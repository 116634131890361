<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('assignments.myAssignmentsArchive') }}
        <small>
          <b-link v-on:click="navigateToMyAssignmentsPage()">({{ $t("assignments.myAssignments") }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <div v-if="loadingMyAssignments" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="this.formattedMyAssignments.length === 0" class="row m-t-20">
      <div class="col-12">
        <b-alert show variant="light" class="text-center" style="font-size: medium">
          {{ $t("assignments.noArchivedAssignments") }}
        </b-alert>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(myA, idx) in formattedMyAssignments"
        :key="idx"
      >
        <RMMyAssignmentPanel :my-assignment="myA"/>
      </div>
    </div>
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {assignmentService} from "@/_services/assignment.service";
import RMMyAssignmentPanel from "@/components/assignments/RMMyAssignmentPanel.vue";
import {isTimeInProgress, isTimeOverdue, isTimeUpcoming} from "@/_helpers/assignments_helper";
import {getMyAssignmentsRoute} from "@/_helpers/routes_helper";

export default {
  name: "MyAssignmentsArchive",
  components: {RMMyAssignmentPanel, RMPageHeader},
  data() {
    return {
      loadingMyAssignments: false,
      myAssignments: []
    }
  },
  computed: {
    formattedMyAssignments() {
      const activeAssignments = this.myAssignments.filter(x => isTimeInProgress(x));
      const upcomingAssignments = this.myAssignments.filter(x => isTimeUpcoming(x));
      const overdueAssignments = this.myAssignments.filter(x => isTimeOverdue(x));
      return [...activeAssignments, ...upcomingAssignments, ...overdueAssignments];
    }
  },
  methods: {
    navigateToMyAssignmentsPage() {
      this.$router.push(getMyAssignmentsRoute());
    },
    loadMyAssignments() {
      this.loadingMyAssignments = true;

      assignmentService.getMyAssignments("ARCHIVED").then((data) => {
        this.myAssignments = data.myAssignments;
      }).finally(() => {
        this.loadingMyAssignments = false;
      })
    },
  },
  created() {
    this.loadMyAssignments();
  }
}
</script>