<template>
  <a :href="eHref">
    <i
      class="fa fa-envelope m-l-10"
      aria-hidden="true"
    />
  </a>
</template>

<script>
export default {
  name: "RMEmail",
  props: {
    eHref: {
      type: String,
      required: true
    }
  }
}
</script>