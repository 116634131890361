<template>
  <RMPanel v-bind:title="getAssTitleHeader(myAssignment)" v-bind:title-is-html="true">
    <template v-slot:panelBody>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.numberOfSolvedTasks") }}:</span>
        <span><b>{{ myAssignment.numSolvedTasks }} / {{ myAssignment.tasks.length }}</b></span>
      </div>
      <div class="m-t-15">
        <RMProgressBar :num-all="myAssignment.tasks.length" :num-solved="myAssignment.numSolvedTasks"/>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.numberOfCorrectTasks") }}:</span>
        <span><b>{{ myAssignment.numCorrectTasks }} / {{ myAssignment.tasks.length }}</b></span>
      </div>
      <div class="m-t-15">
        <RMProgressBar :num-all="myAssignment.tasks.length" :num-solved="myAssignment.numCorrectTasks"/>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.duration") }}:</span>
        <div>
          <div class="d-flex justify-content-end">
            {{ $t("assignments.durations.from") }}
            &nbsp;
            <b>{{ getAssignmentStartDate(myAssignment) }}</b>
          </div>
          <div class="d-flex justify-content-end">
            {{ $t("assignments.durations.to") }}
            &nbsp;
            <b>{{ getAssignmentEndDate(myAssignment) }}</b>
          </div>
        </div>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>{{ $t("mentorship.mentor") }}:</span>
        <span>
          <b>{{ myAssignment.mentor.name }}</b>
          <RMEmail :e-href="getMailtoHref(myAssignment)"/>
        </span>
      </div>
      <div v-if="myAssignment.userStatus === 'ACTIVE'">
        <div v-if="isTimeOverdue(myAssignment)">
          <hr/>
          <div
            class="d-flex justify-content-end"
          >
            <b-button variant="danger" size="sm" @click="archiveAssignment()">
              {{ $t("assignments.buttonTexts.archive") }}
            </b-button>
          </div>
        </div>
        <div v-else-if="!isTimeUpcoming(myAssignment)">
          <hr/>
          <div
            class="d-flex justify-content-end"
            @click="navigateToStartSolvingAssignment(myAssignment)"
          >
            <b-button variant="primary" size="sm">
              <span v-if="isCompleted(myAssignment)">{{ $t("assignments.buttonTexts.check") }}</span>
              <span v-else-if="isAlreadyStarted(myAssignment)">{{ $t("assignments.buttonTexts.continue") }}</span>
              <span v-else>{{ $t("assignments.buttonTexts.start") }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </RMPanel>
</template>

<script>
import RMProgressBar from "@/components/common/RMProgressBar.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import RMEmail from "@/components/common/small/RMEmail.vue";
import {
  getAssignmentTitleHeader,
  isAlreadyStarted,
  isCompleted,
  isTimeOverdue,
  isTimeUpcoming
} from "@/_helpers/assignments_helper";
import {getMyAssignmentRoute} from "@/_helpers/routes_helper";
import {getDateTimeLocale} from "@/_helpers/locale_helper";
import {mapState} from "vuex";
import {assignmentService} from "@/_services/assignment.service";
import {showSweetAlert} from "@/_helpers/api";

export default {
  name: "RMMyAssignmentPanel",
  components: {RMEmail, RMPanel, RMProgressBar},
  props: {
    myAssignment: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    isTimeUpcoming,
    isTimeOverdue,
    isCompleted,
    isAlreadyStarted,
    navigateToStartSolvingAssignment(myAssignment) {
      this.$router.push(getMyAssignmentRoute(myAssignment.assignment_id));
    },
    getAssTitleHeader(assessment) {
      return getAssignmentTitleHeader(assessment, this);
    },
    getMailtoHref(myAssignment) {
      return `mailto:${myAssignment.mentor.email}?subject=${myAssignment.name} (${this.account.user.name})`
    },
    getAssignmentStartDate(assignment) {
      return new Date(assignment.startDate).toLocaleString(getDateTimeLocale())
    },
    getAssignmentEndDate(assignment) {
      return new Date(assignment.endDate).toLocaleString(getDateTimeLocale())
    },
    archiveAssignment() {
      assignmentService.archiveMyAssignment(this.myAssignment.assignment_id).then((data) => {
        showSweetAlert(data.message, this);
        this.$emit("reloadMyAssignments");
      })
    }
  }
}
</script>